import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'

import App from '../components/App'

class Template extends React.Component {
  render() {
    const data = get(this.props, 'data.contentfulAboutUs')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    return (
      <App style={{ background: '#fff' }}>
        <Helmet title={`${data.title} | ${siteTitle}`} />

        <div className="wrapper sectionContainer">
          <h3 className="section-headline">{data.title}</h3>
          <div
            dangerouslySetInnerHTML={{
              __html: data.body.childMarkdownRemark.html,
            }}
          />
        </div>
        <style jsx>{`
          .wrapper {
            padding-top: 120px;
          }
          .section-headline {
            margin-bottom: 40px;
          }
        `}</style>
      </App>
    )
  }
}

export default Template

export const pageQuery = graphql`
  query AboutBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
        description
      }
    }
    contentfulAboutUs(slug: { eq: $slug }) {
      title
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
